import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import FloatingMenu from "../FloatingMenu";
import Columns from "./Columns";
import Loading from "../Loading";
import { ICandidateItem, IKanbanComponent } from "../../models/Kanban";
import {
  generateStatusFromColumnId,
  getColumnItem,
  onDragEnd,
  removeSelectedItemsFromColumn,
} from "../../utils";
import { deleteCandidate } from "../../store_new/reducers/TalentPools";
import { getTalentPoolState } from "../../store_new/selectors/Talentpool";
import { ITalentPool } from "../../models/TalentPools";
import { fetchApplications } from "../../store_new/reducers/Applications";
import { getDialogsState } from "../../store_new/selectors/Dialogs";
import { setCurrentWorkflowStatuses } from "../../store_new/reducers/Workflow";
import {
  getApplicationListState,
  getApplicationsIsListLoading,
  getJobWorkflow,
} from "../../store_new/selectors/Applications";
import { IWorkflowStatus } from "../../models/Workflow";
import { getWorkflowState } from "../../store_new/selectors/Workflow";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";

const KanbanBoard = ({
  t,
  initialColumns,
  statuses,
  variant,
  onChangeStatus,
  onItemClick,
}: IKanbanComponent) => {
  const dispatch = useDispatch();

  const [columns, setColumns] = useState(initialColumns);
  const [selectedItems, setSelectedItems] = useState<ICandidateItem[] | []>([]);
  const isListLoading = useSelector(getApplicationsIsListLoading);
  const jobWorkflow = useSelector(getJobWorkflow);
  const { talentPools } = useSelector(getTalentPoolState);
  const { isColorPickerOpen, isSelectWorkflowDialogOpen } =
    useSelector(getDialogsState);
  const { cardsApplications } = useSelector(getApplicationListState);
  const { isLoading } = useSelector(getSingleJobState);
  const { editWorkflow } = useSelector(getWorkflowState);
  const noCards = variant === "candidate" && !cardsApplications;
  const isDndLoading = noCards || isListLoading || isLoading;
  const isDragDisabled =
    isColorPickerOpen ||
    isSelectWorkflowDialogOpen ||
    Boolean(editWorkflow?.defaultWorkflow);

  const workflowStatuses = useMemo(
    () =>
      jobWorkflow?.statuses.reduce(
        (
          obj: { [x: string]: string },
          item: IWorkflowStatus,
          index: number
        ) => {
          obj[index] = item.value;
          return obj;
        },
        {}
      ),
    [jobWorkflow]
  );

  const changeStatus = (
    draggableId: string,
    droppableId: string,
    index: string
  ) => {
    if (variant === "candidate") {
      const newStatus = generateStatusFromColumnId(
        workflowStatuses,
        droppableId
      );
      onChangeStatus(draggableId, newStatus, index);
    }
  };

  const handleMoveSelectedItems = (status: string) => {
    const removedItemsFromColumns = removeSelectedItemsFromColumn(
      columns,
      selectedItems
    );
    const key = getColumnItem(status, columns) as keyof typeof columns;

    const formattedItemsStatus = selectedItems.map((item) => ({
      ...item,
      status,
      updated_at: moment(),
    }));

    setColumns((prevColumns) => ({
      ...removedItemsFromColumns,
      [key]: {
        color: prevColumns[key]?.color,
        columnId: status,
        title: prevColumns[key]?.title,
        items: prevColumns[key]?.items
          ? [...formattedItemsStatus, ...prevColumns[key].items]
          : formattedItemsStatus,
      },
    }));
    selectedItems.map((item) => {
      onChangeStatus(item.id, status, "0");
    });

    handleDeselectAll();
  };

  const handleSelectItem = (
    event: React.MouseEvent<HTMLButtonElement>,
    newItem: ICandidateItem
  ) => {
    let updatedItems: ICandidateItem[] = [];
    if (selectedItems.findIndex((item) => item.id === newItem.id) > -1) {
      updatedItems = selectedItems?.filter((item) => item.id !== newItem.id);
    } else updatedItems = [...selectedItems, newItem];
    setSelectedItems(updatedItems);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const handleAddNewStatusInWorkflow = () => {
    const currentColumn = { ...columns[0] };
    const newWorkflowStatus = {
      id: columns[0].items.length.toString(),
      label: t("workflow.newState"),
      value: t("workflow.newState"),
      color: "#178CF2",
    };

    const newItems = [...currentColumn.items, newWorkflowStatus];

    setColumns({
      "0": {
        items: newItems,
        columnId: "new",
        title: "New",
        color: "#818CF8",
      },
    });
  };

  useEffect(() => {
    if (variant === "candidate") {
      const allTalentPoolItems = [];
      for (const key in columns) {
        const items = columns[key].items;
        const filteredItems = items.filter(
          (item: any) => item.is_talentpooled == 1 && item.status !== "rejected"
        );
        allTalentPoolItems.push(...filteredItems);
      }
      allTalentPoolItems.forEach((candidate: ICandidateItem) => {
        const findTalentPool = talentPools.find(
          (item: ITalentPool) => item.title === candidate?.talentpool_title
        );
        if (findTalentPool?.id) {
          dispatch(
            deleteCandidate({
              id: findTalentPool?.id as number,
              applicationId: candidate.application_id as unknown as number,
              callback: () => {
                dispatch(fetchApplications({ withoutLoader: true }));
              },
            })
          );
        }
      });
    } else {
      const newWorkflowColors = [...columns[0].items];
      dispatch(setCurrentWorkflowStatuses(newWorkflowColors));
    }
  }, [columns]);

  useEffect(() => {
    if (variant === "candidate") {
      setColumns(cardsApplications);
    }
  }, [cardsApplications]);

  return (
    <>
      {isDndLoading ? (
        <Loading />
      ) : (
        <DragDropContext
          onDragEnd={(result) =>
            onDragEnd(result, columns, setColumns, changeStatus)
          }
        >
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {(provided) => (
              <Columns
                provided={provided}
                t={t}
                columns={columns}
                variant={variant}
                selectedItems={selectedItems}
                isDragDisabled={isDragDisabled}
                isSelectWorkflowDialogOpen={isSelectWorkflowDialogOpen}
                editWorkflow={editWorkflow}
                onAddNewStatus={handleAddNewStatusInWorkflow}
                setColumns={setColumns}
                onSelectItem={handleSelectItem}
                onItemClick={onItemClick}
              />
            )}
          </Droppable>
        </DragDropContext>
      )}
      {selectedItems.length > 0 && (
        <FloatingMenu
          t={t}
          items={selectedItems}
          statuses={statuses}
          handleDeselectAll={handleDeselectAll}
          handleMoveAll={handleMoveSelectedItems}
        />
      )}
    </>
  );
};

export default KanbanBoard;
