import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import {
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { IJobDetailsLocationsComponent } from "../../../models/JobEditor";
import AutocompleteFilter from "../../filters_new/AutocompleteFilter";
import {
  StyledLocationsRowFieldsContainer,
  StyledRadioButtonContainer,
  StyledSubSectionContainer,
} from "../styles";

const LocationsSection = ({
  t,
  errors,
  control,
  register,
  locations,
  countries,
  jobTypes,
  onAddLocation,
  onRemoveLocation,
}: IJobDetailsLocationsComponent) => (
  <>
    <Typography variant="body2">
      {t("create_job_second_step.location")}
    </Typography>
    <StyledSubSectionContainer>
      {locations.map(({ id }: { id: string }, index: number) => (
        <StyledLocationsRowFieldsContainer key={id} direction={"row"}>
          <Stack
            sx={{ flex: 3 }}
            mb={errors["locations"]?.[index]?.["country"] ? 1.75 : 0}
          >
            <InputLabel>{t("update_company_information.country")}</InputLabel>
            <Controller
              name={`locations.${index}.country`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutocompleteFilter
                  placeholder={
                    t("update_company_information.country") as string
                  }
                  options={countries}
                  value={value}
                  onChange={onChange}
                  hasFlags
                  errors={errors["locations"]?.[index]?.["country"]}
                />
              )}
            />
          </Stack>
          <Stack sx={{ flex: 3 }}>
            <InputLabel>{t("update_company_information.city")}</InputLabel>
            <TextField
              placeholder={t("update_company_information.city") as string}
              fullWidth
              {...register(`locations.${index}.city`)}
              error={Boolean(errors["locations"]?.[index]?.["city"])}
              helperText={errors["locations"]?.[index]?.["city"]?.message}
            />
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <InputLabel>{t("update_company_information.postcode")}</InputLabel>
            <TextField
              placeholder={t("update_company_information.postcode") as string}
              fullWidth
              {...register(`locations.${index}.zip`)}
              error={Boolean(errors["locations"]?.[index]?.["zip"])}
              helperText={errors["locations"]?.[index]?.["zip"]?.message}
            />
          </Stack>
          <Stack
            alignSelf={"center"}
            mt={errors["locations"]?.[index] ? 0 : 2.5}
          >
            {index === 0 ? (
              <PlusCircleIcon
                className={"add-icon-button"}
                onClick={onAddLocation}
              />
            ) : (
              <XCircleIcon
                className={"remove-icon-button"}
                onClick={() => onRemoveLocation(index)}
              />
            )}
          </Stack>
        </StyledLocationsRowFieldsContainer>
      ))}
      <Stack mt={2}>
        <Controller
          name={"job_type"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup value={value} onChange={onChange}>
              <Stack direction={"row"} spacing={3}>
                {jobTypes.map((jobType) => (
                  <StyledRadioButtonContainer
                    onClick={() => onChange(jobType.id)}
                    selected={value === jobType.id}
                    key={jobType.id}
                  >
                    <FormControlLabel
                      className={"form-label"}
                      value={jobType.id}
                      control={<Radio size={"small"} />}
                      label={t(
                        `create_job_second_step.jobTypes.${jobType.title.toLowerCase()}`,
                      )}
                    />
                  </StyledRadioButtonContainer>
                ))}
              </Stack>
            </RadioGroup>
          )}
        />
      </Stack>
    </StyledSubSectionContainer>
  </>
);

export default LocationsSection;
