import React from "react";
import { Button, Grid } from "@mui/material";
import Loading from "../../components/Loading";
import AdditionalInfo from "./AdditionalInfo";
import Documents from "./Documents";
import PersonalInfo from "./PersonalInfo";
import CandidateMessages from "../../components/CandidateMessages";
import ActivityLog from "../../components/ActivityLog";
import OutdatedApplication from "./OutdatedApplication";
import { StyledMessageBlockContainer } from "./styles";
import {
  IApplicationPageComponent,
  TSelectedJobCandidateApplication,
} from "../../models/ApplicationPage";

const ApplicationPageComponent = ({
  t,
  desktop,
  isCandidate,
  isAgencyOrCandidate,
  isLoading,
  currentApplication,
  activityLog,
  onConfirmOutdatedApplication,
  onRequestUnlockApplication,
  handleOnMessage,
}: IApplicationPageComponent) =>
  isLoading ? (
    <Loading />
  ) : currentApplication?.is_gdpr_outdated && isCandidate === false ? (
    <OutdatedApplication
      t={t}
      currentApplication={currentApplication}
      onRequestUnlockApplication={onRequestUnlockApplication}
    />
  ) : (
    <>
      {!isAgencyOrCandidate && (
        <StyledMessageBlockContainer direction={"row"}>
          <span>
            {t("candidate.send_direct_message") +
              currentApplication?.firstname +
              " " +
              currentApplication?.lastname}
            .
          </span>
          <Button variant="contained" onClick={handleOnMessage}>
            {t("messages_page.new_message_placeholder")}
          </Button>
        </StyledMessageBlockContainer>
      )}
      {/*  //currentApplication?.is_unlock_requested && isCandidate  */}
      {!!currentApplication?.is_unlock_requested && !!isCandidate && (
        <StyledMessageBlockContainer
          direction={"row"}
          border="none !important"
          sx={{ background: "#EDF7FF" }}
        >
          <span style={{ maxWidth: "80%", whiteSpace: "normal" }}>
            {t("candidate.confirm_request_text")}
          </span>

          <Button variant="contained" onClick={onConfirmOutdatedApplication}>
            {t("candidate.confirm_request")}
          </Button>
        </StyledMessageBlockContainer>
      )}
      <Grid container spacing={3} py={3}>
        <Grid container item spacing={3}>
          <Grid item xs={desktop ? 8 : 12}>
            <PersonalInfo
              currentApplication={currentApplication}
              desktop={desktop}
            />
          </Grid>
          <Grid item xs={desktop ? 4 : 12}>
            <Documents
              currentApplication={currentApplication}
              desktop={desktop}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={isAgencyOrCandidate ? 12 : 8}>
            <AdditionalInfo />
          </Grid>
          <Grid item xs={4}>
            {!isAgencyOrCandidate && (
              <CandidateMessages
                info={currentApplication || []}
                displayAdditionalInfo={true}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {!isCandidate && activityLog && activityLog?.length > 0 && (
              <ActivityLog
                t={t}
                activityLog={activityLog}
                currentApplication={
                  currentApplication as TSelectedJobCandidateApplication
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

export default ApplicationPageComponent;
