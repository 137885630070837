import axios, { AxiosPromise } from "axios";

const lang = localStorage.getItem("lang");

export const axiosInstance = axios.create({
  baseURL: "https://be.staging.karriera.de/",
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept-Language": lang,
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : undefined;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.status;
    const token = localStorage.getItem("token");
    if (status >= 500 && status <= 599) {
      error.response.data = {
        error: {
          message: "Internal server error. Please contact your administrator!",
        },
      };
    } else if (status === 401 && token) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export const axiosInstanceDownload = axios.create({
  baseURL: "https://be.staging.karriera.de/",
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept-Language": lang,
  },
  responseType: "blob",
});

axiosInstanceDownload.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : undefined;
  return config;
});

axiosInstanceDownload.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.status;
    const token = localStorage.getItem("token");
    if (status >= 500 && status <= 599) {
      error.response.data = {
        error: {
          message: "Internal server error. Please contact your administrator!",
        },
      };
    } else if (status === 401 && token) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export const apiRequest = async ({
  type,
  url,
  apiPayload,
}: {
  type: string;
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiPayload?: any;
}) => {
  const lang = localStorage.getItem("lang");
  let request: AxiosPromise;
  switch (type) {
    case "get":
      request = axiosInstance.get(url, {
        headers: {
          "Accept-Language": lang,
        },
      });
      break;
    case "put":
      request = axiosInstance.put(url, apiPayload, {
        headers: {
          "Accept-Language": lang,
        },
      });
      break;
    case "delete":
      request = axiosInstance.delete(url, {
        headers: {
          "Accept-Language": lang,
        },
      });
      break;
    case "post-json": {
      request = axiosInstance.post(url, apiPayload, {
        headers: {
          "Accept-Language": lang,
          "Content-Type": " application/json",
        },
      });
      break;
    }
    case "post":
    default:
      request = axiosInstance.post(url, apiPayload, {
        headers: {
          "Accept-Language": lang,
        },
      });
  }
  return request
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};
