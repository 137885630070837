import React from "react";
import { Stack, Checkbox } from "@mui/material";
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import ActionsCell from "../cellRenderers/actions";
import CVCell from "../cellRenderers/cv";
import StatusSelectorWithDaysInStatus from "../cellRenderers/statusSelectorWithDaysInStatus";
import StatusSelectorCell from "../cellRenderers/statusSelector";
import GDPR from "../cellRenderers/gdpr";
import {
  DATA_GRID_LIST_TYPE,
  TDataGridGetColDef,
} from "../../../models/DataGrid";

export const getApplicationsColDef = ({
  t,
  actions,
  onSelect,
  type,
}: Pick<
  TDataGridGetColDef,
  "t" | "actions" | "onSelect" | "type"
>): ColDef[] => [
  {
    field: "",
    width: 76,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "ag-grid-cell-checkbox": true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    cellRenderer: (params: ICellRendererParams) =>
      params.data.is_gdpr_outdated ? (
        ""
      ) : (
        <Checkbox
          disabled={params.data.is_gdpr_outdated}
          onClick={() => onSelect && onSelect(params.data)}
          color="secondary"
        />
      ),
  },
  {
    field: "name",
    headerName: t("applications.name") as string,
    cellStyle: { cursor: "pointer" },
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "ag-grid-cell-clickable": true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    valueGetter: (params: ValueGetterParams) => {
      const { firstname, lastname } = params.data;
      return `${firstname} ${lastname}`;
    },

    flex: 0.6,
    onCellClicked: (params: CellClickedEvent) => {
      actions.onEdit && actions.onEdit(params.data);
    },
  },
  {
    field: "location",
    headerName: t("joblist.location_placeholder") as string,
    flex: 0.5,
    maxWidth: 200,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    valueGetter: (params: ValueGetterParams) =>
      params.data.is_gdpr_outdated ? "" : params.data.location,
  },
  {
    field: "documents",
    headerName: "CV",
    flex: 0.7,
    //maxWidth: 260,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "gdpr-text": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    cellRenderer: (params: ICellRendererParams) => (
      <Stack height={56} justifyContent="center">
        {params.data.is_gdpr_outdated ? (
          <GDPR
            table="big"
            isUnlockRequested={params.data.is_unlock_requested}
            applicationId={params.data.application_id}
          />
        ) : (
          <CVCell
            cv={params.data.documents[0]}
            onDownloadCV={actions.onDownloadCV}
          />
        )}
      </Stack>
    ),
  },
  {
    field: "title",
    headerName: t("applications.applied_to") as string,
    flex: 0.7,
    maxWidth: 260,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    valueGetter: (params: ValueGetterParams) =>
      params.data.is_gdpr_outdated ? "" : params.data.title,
    hide: type === DATA_GRID_LIST_TYPE.APPLICATION_LIST_SINGLE_JOB,
  },
  {
    field: "timestamp",
    headerName: t("applications.date_applied") as string,
    flex: 0.6,
    maxWidth: 150,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    valueGetter: (params: ValueGetterParams) =>
      params.data.is_gdpr_outdated
        ? ""
        : new Date(params.data["timestamp"]).toLocaleDateString(),
  },
  {
    field: "status",
    flex: 0.6,
    maxWidth: 200,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "ag-grid-status-with-days": true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    cellRenderer: (params: ICellRendererParams) =>
      params.data.is_gdpr_outdated ? (
        ""
      ) : (
        <StatusSelectorWithDaysInStatus params={params} actions={actions} />
      ),
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    flex: 0.3,
    maxWidth: 70,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    cellStyle: { justifyContent: "center" },
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onDownloadCV: params.data.documents?.length
            ? () =>
                actions.onDownloadCV &&
                actions.onDownloadCV(params.data.documents[0].link)
            : undefined,
          onExport: () => actions.onExport && actions.onExport(params.data),
          onRemove: () => actions.onRemove && actions.onRemove(params.data),
        },
        type: params.data.status,
        forceMultiple: true,
      }),
  },
];

export const getSimpleApplicationsColDef = ({
  t,
  actions,
}: Pick<TDataGridGetColDef, "t" | "actions">): ColDef[] => [
  {
    field: "name",
    cellStyle: { cursor: "pointer" },
    headerName: t("applications.name") as string,
    onCellClicked: (params: CellClickedEvent) => {
      actions.onEdit && actions.onEdit(params.data);
    },
    valueGetter: (params: ValueGetterParams) => {
      const { firstname, lastname } = params.data;
      return `${firstname} ${lastname}`;
    },
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    flex: 1,
  },
  {
    field: "title",
    cellStyle: { cursor: "pointer" },
    headerName: t("applications.applied_to") as string,
    flex: 1,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    cellRenderer: (params: ICellRendererParams) => (
      <Stack height={56} justifyContent="center">
        {params.data.is_gdpr_outdated ? (
          <GDPR
            table="small"
            isUnlockRequested={params.data.is_unlock_requested}
            applicationId={params.data.application_id}
          />
        ) : (
          params.data.title
        )}
      </Stack>
    ),
  },
  {
    field: "timestamp",
    cellStyle: { cursor: "pointer" },
    headerName: t("applications.date_applied") as string,
    flex: 0.9,
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    valueGetter: (params: ValueGetterParams) =>
      params.data.is_gdpr_outdated
        ? ""
        : new Date(params.data["timestamp"]).toLocaleDateString(),
  },
  {
    field: "status",
    cellStyle: { cursor: "pointer" },
    cellClassRules: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "ag-grid-status-with-days": true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "outdated-cell": (params: ICellRendererParams) =>
        params.data.is_gdpr_outdated,
    },
    flex: 0.6,
    cellRenderer: (params: ICellRendererParams) =>
      params.data.is_gdpr_outdated ? (
        ""
      ) : (
        <StatusSelectorCell
          params={params}
          label={params.value}
          type={params.value}
        />
      ),
  },
];
