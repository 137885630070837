import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  fetchAnalyticsJobList,
  fetchAnalyticsJobListFailed,
  fetchAnalyticsJobListSuccess,
  fetchAnalyticsNumberOfApplications,
  fetchAnalyticsNumberOfApplicationsFailed,
  fetchAnalyticsNumberOfApplicationsSuccess,
} from "../reducers/Analytics";
import {
  apiFetchAnalyticsJobList,
  apiFetchAnalyticsNumberOfApplications,
} from "../../api/Analytics";
import { PayloadAction } from "@reduxjs/toolkit";
import { getAnalyticsSelectedJob } from "../selectors/Analytics";
import { TListOption } from "../../models/common";
import { TJob } from "../../models/CompanyInfo";

function* handleOnFetchAnalyticsJobList() { 
  try {
    const { data } = yield call(apiFetchAnalyticsJobList);
    const result: TListOption[] = data.jobs.map(({ title, url_key }: TJob) => ({
      label: title,
      value: url_key
    }));
    yield put(fetchAnalyticsJobListSuccess(result));
  } catch (e) {
    yield put(fetchAnalyticsJobListFailed(e)); // TODO define type of error and handle error
  }
}

function* handleOnFetchAnalyticsNumberOfApplications({
  payload
}: PayloadAction<{ since: string; until: string }>) {
  const selectedJob: null | TListOption = yield select(getAnalyticsSelectedJob);

  try {
    const { data } = yield call(apiFetchAnalyticsNumberOfApplications, {
      jobUrlKey: selectedJob?.value || "",
      apiPayload: payload
    });
    yield put(fetchAnalyticsNumberOfApplicationsSuccess(data));
  } catch (e) {
    yield put(fetchAnalyticsNumberOfApplicationsFailed(e)); // TODO handle error
  }
}

function* AnalyticsSaga() {
  yield takeLatest(fetchAnalyticsJobList, handleOnFetchAnalyticsJobList);
  yield takeLatest(
    fetchAnalyticsNumberOfApplications,
    handleOnFetchAnalyticsNumberOfApplications
  );
}

export default AnalyticsSaga;
