import { ColDef } from "ag-grid-community";
import {
  DATA_GRID_LIST_TYPE,
  TDataGridGetColDef,
} from "../../../models/DataGrid";
import {
  getApplicationsColDef,
  getSimpleApplicationsColDef,
} from "./Applications";
import getBasicJobBoardsColDef from "./BasicJobBoards";
import getJobsColDef from "./Jobs";
import getUsersColDef from "./Users";
import getBookingsColDef from "./Bookings";
import getCustomersColDef from "./Customers";
import getAgencyJobsColDef from "./AgencyJobs";
import getImportCustomerColDef from "./ImportCustomer";
import getTalentPoolsColDef from "./TalentPools";
import getTalentPoolCandidateColDef from "./TalentPoolCandidate";
import getTalentPoolJobsColDef from "./TalentPoolJobs";

const getColDefs = ({
  type,
  actions,
  t,
  onSelect,
}: TDataGridGetColDef): ColDef[] => {
  switch (type) {
    case DATA_GRID_LIST_TYPE.USERS:
      return getUsersColDef({ t, actions });
    case DATA_GRID_LIST_TYPE.JOB_LIST_ACTIVE:
    case DATA_GRID_LIST_TYPE.JOB_LIST_ARCHIVED:
    case DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT:
      return getJobsColDef({
        t,
        actions,
        onSelect,
        type,
      });
    case DATA_GRID_LIST_TYPE.AGENCY_JOB_LIST_ACTIVE:
    case DATA_GRID_LIST_TYPE.AGENCY_JOB_LIST_ARCHIVED:
    case DATA_GRID_LIST_TYPE.AGENCY_JOB_LIST_DRAFT:
      return getAgencyJobsColDef({
        t,
        actions,
        onSelect,
        type,
      });
    case DATA_GRID_LIST_TYPE.TALENT_POOLS:
      return getTalentPoolsColDef({
        t,
        actions,
        type,
      });
    case DATA_GRID_LIST_TYPE.APPLICATION_LIST:
    case DATA_GRID_LIST_TYPE.APPLICATION_LIST_SINGLE_JOB:
      return getApplicationsColDef({
        t,
        actions,
        onSelect,
        type,
      });
    case DATA_GRID_LIST_TYPE.APPLICATION_LIST_DASHBOARD:
      return getSimpleApplicationsColDef({ t, actions });
    case DATA_GRID_LIST_TYPE.BASIC_JOB_BOARDS:
      return getBasicJobBoardsColDef({ t, actions });
    case DATA_GRID_LIST_TYPE.BOOKINGS:
      return getBookingsColDef({ t });
    case DATA_GRID_LIST_TYPE.CUSTOMERS:
      return getCustomersColDef({
        t,
        actions,
        onSelect,
        type,
      });
    case DATA_GRID_LIST_TYPE.IMPORT_CUSTOMERS:
      return getImportCustomerColDef({
        t,
        onSelect,
      });
    case DATA_GRID_LIST_TYPE.TALENT_POOL_CANDIDATE:
      return getTalentPoolCandidateColDef({
        t,
        actions,
        onSelect,
      });
    case DATA_GRID_LIST_TYPE.TALENT_POOL_JOBS:
      return getTalentPoolJobsColDef({
        t,
      });
    default:
      return [];
  }
};

export const defaultColDef: ColDef = {
  cellStyle: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

export default getColDefs;
