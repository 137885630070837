import React, { useEffect } from "react";
import AnalyticsComponent from "./component";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsIsLoading,
  getAnalyticsJobList,
} from "../../store_new/selectors/Analytics";
import {
  fetchAnalyticsJobList,
  resetAnalytics,
} from "../../store_new/reducers/Analytics";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import PremiumRequestComponent from "./PremiumRequestComponent";

const Analytics = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getAnalyticsIsLoading);
  const jobList = useSelector(getAnalyticsJobList);
  const { isAnalyticsAllowed } = useSelector(getCurrentUserData);

  useEffect(() => {
    return () => {
      dispatch(resetAnalytics());
    };
  }, []);

  useEffect(() => {
    if (!isLoading && !jobList && isAnalyticsAllowed)
      dispatch(fetchAnalyticsJobList());
  }, [jobList, isLoading]);

  return isAnalyticsAllowed ? (
    <AnalyticsComponent isLoading={isLoading} />
  ) : (
    <PremiumRequestComponent />
  );
};

export default Analytics;
