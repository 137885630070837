import React from "react";
import ReactApexChart from "react-apexcharts";
import { Stack } from "@mui/material";
import {
  StyledChartContainer,
  StyledChartContainerTitle,
  StyledNoDataContainer,
} from "./styles";
import { NOAChartOptions } from "./utils";
import { useSelector } from "react-redux";
import {
  getAnalyticsNOAIsLoadingState,
  getAnalyticsNOATargetLabels,
  getAnalyticsNOATargetValues,
} from "../../store_new/selectors/Analytics";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";

const NumberOfApplications = () => {
  const { t } = useTranslation();

  const isLoading = useSelector(getAnalyticsNOAIsLoadingState);
  const values = useSelector(getAnalyticsNOATargetValues);
  const labels = useSelector(getAnalyticsNOATargetLabels);

  return (
    <StyledChartContainer>
      <StyledChartContainerTitle>
        <h4>{t("analytics.numberOfApplications")}</h4>
      </StyledChartContainerTitle>
      {isLoading ? (
        <Stack maxHeight={350}>
          <Loading />
        </Stack>
      ) : values?.length ? (
        <ReactApexChart
          series={[
            { name: t("analytics.applications") as string, data: values },
          ]}
          options={{ ...NOAChartOptions, labels: labels || [] }}
          height={350}
          className={"chart"}
          type={"area"}
        />
      ) : (
        <StyledNoDataContainer>
          <h5>{t("analytics.noDataText")}</h5>
          <h6>{t("analytics.changeDateFilter")}</h6>
        </StyledNoDataContainer>
      )}
    </StyledChartContainer>
  );
};

export default NumberOfApplications;
