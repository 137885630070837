import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  ICandidateState,
  TCandidateApplication,
  TCandidateApplicationDocument,
} from "../../models/ApplicationPage";
import { PayloadActionWithCallback } from "../../models/common";

const initialState: ICandidateState = {
  profile: {
    email: "",
    firstname: "",
    lastname: "",
    photo: "",
    latest_cv_link: "",
    linkedin_url: "",
    xing_url: "",
    lang: "en_US",
    noPassword: 0,
  },
  candidateJobUrlKey: "",
  applications: [],
  countries: [],
  isLoading: false,
  isCandidate: true,
  error: "",
};

const applicationsReducers = {
  updateIsCandidateState: (
    state: Draft<ICandidateState>,
    action: PayloadAction<{ isCandidate: boolean }>
  ) => {
    state.isCandidate = action.payload.isCandidate;
  },
  setCandidateJobUrlKey: (
    state: Draft<ICandidateState>,
    action: PayloadAction<{ jobUrlKey: string }>
  ) => {
    state.candidateJobUrlKey = action.payload.jobUrlKey;
  },
  fetchCandidateApplications: (state: Draft<ICandidateState>) => {
    state.isLoading = true;
  },
  fetchCandidateApplicationsSuccess: (
    state: Draft<ICandidateState>,
    action: PayloadAction<{
      applications: TCandidateApplication[];
    }>
  ) => {
    const { applications } = action.payload;
    state.isLoading = false;
    state.applications = applications;
  },
  fetchCandidateApplicationsFailed: (
    state: Draft<ICandidateState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  updateCandidateApplication: (
    state: Draft<ICandidateState>,
    _: PayloadActionWithCallback<{
      applicationId: string;
      phone: string;
      location: string;
      country: string;
    }>
  ) => {
    state.isLoading = true;
  },
  updateCandidateApplicationFailed: (
    state: Draft<ICandidateState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  uploadCandidateDocument: (
    state: Draft<ICandidateState>,
    _: PayloadActionWithCallback<{
      jobUrlKey: string;
      files: [File];
      type: "documents" | "cv";
    }>
  ) => {
    state.isLoading = true;
  },
  uploadCandidateDocumentFailed: (
    state: Draft<ICandidateState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  downloadCandidateDocument: (
    __: Draft<ICandidateState>,
    _: PayloadAction<{
      doc: TCandidateApplicationDocument;
    }>
  ) => {},
  downloadCandidateDocumentFailed: (
    state: Draft<ICandidateState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  deleteCandidateDocument: (
    state: Draft<ICandidateState>,
    _: PayloadActionWithCallback<{
      doc: TCandidateApplicationDocument;
    }>
  ) => {
    state.isLoading = true;
  },
  deleteCandidateDocumentFailed: (
    state: Draft<ICandidateState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  unlockCandidateApplication: (
    state: Draft<ICandidateState>,
    _: PayloadActionWithCallback<{
      href: string;
      applicationId: number;
    }>
  ) => {
    state.isLoading = true;
  },
  unlockCandidateApplicationSuccess: (state: Draft<ICandidateState>) => {
    state.isLoading = false;
  },
  unlockCandidateApplicationFailed: (
    state: Draft<ICandidateState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

const countriesReducers = {
  fetchCountries: (state: Draft<ICandidateState>) => {
    state.isLoading = true;
  },
  fetchCountriesSuccess: (
    state: Draft<ICandidateState>,
    action: PayloadAction<{
      countries: any;
    }>
  ) => {
    const { countries } = action.payload;
    state.isLoading = false;
    state.countries = countries;
  },
  fetchCountriesFailed: (
    state: Draft<ICandidateState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const CandidateSlice = createSlice({
  name: "CANDIDATE",
  initialState,
  reducers: {
    ...applicationsReducers,
    ...countriesReducers,
  },
});

export const {
  updateIsCandidateState,
  setCandidateJobUrlKey,
  fetchCandidateApplications,
  fetchCandidateApplicationsSuccess,
  fetchCandidateApplicationsFailed,
  updateCandidateApplication,
  updateCandidateApplicationFailed,
  uploadCandidateDocument,
  uploadCandidateDocumentFailed,
  fetchCountries,
  fetchCountriesSuccess,
  fetchCountriesFailed,
  downloadCandidateDocument,
  downloadCandidateDocumentFailed,
  deleteCandidateDocument,
  deleteCandidateDocumentFailed,
  unlockCandidateApplication,
  unlockCandidateApplicationSuccess,
  unlockCandidateApplicationFailed,
} = CandidateSlice.actions;

export default CandidateSlice.reducer;
