import React from "react";
import { Stack } from "@mui/material";
import JobMediaPreview from "./preview/JobMedia";
import Loading from "../../../components/Loading";
import JobBasicQuestionsPreview from "./preview/JobBasicQuestions";
import JobDescriptionPreview from "./preview/JobDescription";
import JobDetailsPreview from "./preview/JobDetails";
import JobDocumentsPreview from "./preview/JobDocuments";
import JobAdditionalQuestionsPreview from "./preview/JobAdditionalQuestions";
import {
  DIALOG_TYPE,
  IJobDetailsComponent,
  SELECTED_EDIT_SECTIONS,
} from "../../../models/SingleJob";

const JobDetailsComponent = ({
  t,
  jobTemplate,
  jobDetails,
  jobLocations,
  jobQuestions,
  jobTypes,
  isLoading,
  companyColor,
  onEdit,
}: IJobDetailsComponent) =>
  isLoading ? (
    <Loading />
  ) : (
    <Stack spacing={2} pb={2}>
      <JobMediaPreview
        t={t}
        jobTemplate={jobTemplate}
        companyColor={companyColor}
        onEdit={() =>
          onEdit(DIALOG_TYPE.JOB_DESCRIPTION, [
            SELECTED_EDIT_SECTIONS.JOB_DESCRIPTION_MEDIA_SECTION,
          ])
        }
      />
      <JobDescriptionPreview
        t={t}
        jobTemplate={jobTemplate}
        onEdit={() =>
          onEdit(DIALOG_TYPE.JOB_DESCRIPTION, [
            SELECTED_EDIT_SECTIONS.JOB_DESCRIPTION_MAIN_SECTION,
          ])
        }
      />
      <JobDetailsPreview
        t={t}
        jobDetails={jobDetails}
        jobLocations={jobLocations}
        jobTypes={jobTypes}
        onEdit={() => onEdit(DIALOG_TYPE.JOB_DETAILS)}
      />
      <JobBasicQuestionsPreview
        t={t}
        jobDetails={jobDetails}
        onEdit={() => onEdit(DIALOG_TYPE.JOB_BASIC_QUESTIONS)}
      />
      <JobDocumentsPreview
        t={t}
        jobDetails={jobDetails}
        onEdit={() => onEdit(DIALOG_TYPE.JOB_DOCUMENTS)}
      />
      <JobAdditionalQuestionsPreview
        t={t}
        jobQuestions={jobQuestions}
        onEdit={() => onEdit(DIALOG_TYPE.JOB_ADDITIONAL_QUESTION)}
      />
    </Stack>
  );

export default JobDetailsComponent;
