import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { TListOption } from "../../models/common";

export interface IAnalyticsStateInterface {
  readonly jobList: null | TListOption[];
  readonly selectedJob: null | TListOption;
  readonly isLoading: boolean;
  readonly error: unknown;
  readonly numberOfApplications: {
    readonly valuesByDay: null | number[];
    readonly valuesByMonth: null | number[];
    readonly labelsByDay: null | string[];
    readonly labelsByMonth: null | string[];
    readonly sources: { source: string; quantity: number }[];
    readonly error: unknown;
    readonly isLoading: boolean;
  };
}

const initialState: IAnalyticsStateInterface = {
  jobList: null,
  selectedJob: null,
  isLoading: false,
  error: "",
  numberOfApplications: {
    valuesByDay: null,
    valuesByMonth: null,
    labelsByDay: null,
    labelsByMonth: null,
    sources: [],
    error: "",
    isLoading: false,
  },
};

export const AnalyticsSlice = createSlice({
  name: "ANALYTICS",
  initialState,
  reducers: {
    fetchAnalyticsJobList: (state: Draft<IAnalyticsStateInterface>) => {
      state.isLoading = true;
    },
    fetchAnalyticsJobListSuccess: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<TListOption[]>,
    ) => {
      state.jobList = action.payload;
      state.isLoading = false;
    },
    fetchAnalyticsJobListFailed: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<unknown>,
    ) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setAnalyticsSelectedJob: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<TListOption | null>,
    ) => {
      state.selectedJob = action.payload;
    },
    fetchAnalyticsNumberOfApplications: (
      state: Draft<IAnalyticsStateInterface>,
      _: PayloadAction<{ since: string; until: string }>,
    ) => {
      state.numberOfApplications.isLoading = true;
    },
    fetchAnalyticsNumberOfApplicationsSuccess: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<{
        byDay: { date: string; quantity: number }[];
        byMonth: { date: string; quantity: number }[];
        source: { source: string; quantity: number }[];
      }>,
    ) => {
      const { byMonth, byDay, source } = action.payload;
      state.numberOfApplications.valuesByMonth = byMonth.map(
        ({ quantity }) => quantity,
      );
      state.numberOfApplications.labelsByMonth = byMonth.map(
        ({ date }) => date,
      );
      state.numberOfApplications.valuesByDay = byDay.map(
        ({ quantity }) => quantity,
      );
      state.numberOfApplications.labelsByDay = byDay.map(({ date }) => date);
      state.numberOfApplications.sources = source;
      state.numberOfApplications.isLoading = false;
    },
    fetchAnalyticsNumberOfApplicationsFailed: (
      state: Draft<IAnalyticsStateInterface>,
      action: PayloadAction<unknown>,
    ) => {
      state.numberOfApplications.error = action.payload;
      state.numberOfApplications.isLoading = false;
    },
    resetAnalytics: () => initialState,
  },
});

export const {
  fetchAnalyticsJobList,
  fetchAnalyticsJobListSuccess,
  fetchAnalyticsJobListFailed,
  setAnalyticsSelectedJob,
  fetchAnalyticsNumberOfApplications,
  fetchAnalyticsNumberOfApplicationsSuccess,
  fetchAnalyticsNumberOfApplicationsFailed,
  resetAnalytics,
} = AnalyticsSlice.actions;

export default AnalyticsSlice.reducer;
