import { Button, Stack } from "@mui/material";
import disabledAnalytics from "../../assets/disabledAnalytics.png";
import React from "react";
import {
  StyledPremiumRequestBGContainer,
  StyledPremiumRequestBGShadeContainer,
  StyledPremiumRequestContainer,
  StyledPremiumRequestPaperContainer,
} from "./styles";
import { useTranslation } from "react-i18next";

const PremiumRequestComponent = () => {
  const { t } = useTranslation();
  return (
    <StyledPremiumRequestContainer>
      <StyledPremiumRequestBGContainer />
      <StyledPremiumRequestBGShadeContainer />
      <StyledPremiumRequestPaperContainer>
        <Stack className={"image-container"}>
          <img alt={"analytics PRO"} src={disabledAnalytics} />
        </Stack>
        <Stack className={"title-container"}>
          <h2>{t("limit_dialog.usePro")}</h2>
        </Stack>
        <Stack className={"main-text"}>{t("limit_dialog.text")}</Stack>
        <Button fullWidth variant={"contained"}>
          {t("limit_dialog.button")}
        </Button>
      </StyledPremiumRequestPaperContainer>
    </StyledPremiumRequestContainer>
  );
};

export default PremiumRequestComponent;
