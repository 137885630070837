import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  IApplicationsState,
  TApplication,
  TApplicationsFilters,
} from "../../models/Applications";
import { TSelectedJobCandidateApplication } from "../../models/ApplicationPage";
import { PayloadActionWithCallback } from "../../models/common";
import { TJobOwner } from "../../models/SingleJob";
import { IWorkflow } from "../../models/Workflow";
import { IInitialColumns } from "../../models/Kanban";

const initialState: IApplicationsState = {
  filters: {
    quickSearch: "",
    location: "",
    appliedTo: { options: [], value: "" },
    status: { options: [], value: "" },
    company: { options: [], value: "" },
  },
  pagination: {
    pageNum: 1,
    pageSize: 10,
  },
  applications: {
    list: [],
    selectedJobApplications: [],
    cardsApplications: undefined,
    totalCount: "0",
  },
  view: "table",
  allApplications: null,
  selectedApplications: [],
  error: false,
  isLoading: false,
  isOwnersLoading: false,
  isListLoading: false,
  isDeleteApplicationLoading: false,
  isUpdateAnswersLoading: false,
  candidateAnswers: [],
  selectedJobOwners: undefined,
  activityLog: undefined,
  jobUrlKey: null,
  jobWorkflow: null,
  isRequestUnlockLoading: false,
};

const filtersReducer = {
  fetchApplicationFromTalentPool: (
    state: Draft<IApplicationsState>,
    _: PayloadActionWithCallback<{ applicationId: number }>
  ) => {
    state.isLoading = true;
  },
  fetchApplicationFromTalentPoolSuccess: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<TApplication | null>
  ) => {
    state.selectedApplications = action.payload
      ? [action.payload]
      : initialState.selectedApplications;
  },
  fetchApplicationFromTalentPoolFailed: (
    state: Draft<IApplicationsState>,
    error: PayloadAction<unknown>
  ) => {
    state.error = error;
  },
  setView: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<IApplicationsState["view"]>
  ) => {
    state.view = action.payload;
  },
  setApplicationFilters: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<{
      filterType: keyof TApplicationsFilters;
      value: string;
    }>
  ) => {
    const { filterType, value } = action.payload;
    switch (filterType) {
      case "appliedTo":
      case "company":
      case "status":
        state.filters[filterType].value = value;
        break;
      case "location":
      case "quickSearch":
        state.filters[filterType] = value;
    }
  },
  setFilterOptions: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<{
      readonly filter: keyof Pick<TApplicationsFilters, "appliedTo" | "status">;
      readonly options: {
        readonly value: string;
        readonly label: string;
      }[];
    }>
  ) => {
    const { options, filter } = action.payload;
    state.filters[filter].options = options;
  },
  resetApplicationsFilters: (state: Draft<IApplicationsState>) => {
    state.filters = initialState.filters;
  },
  setJobWorkflow: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<IWorkflow | null>
  ) => {
    state.jobWorkflow = action.payload;
  },
};

const paginationReducer = {
  setPageNum: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<number>
  ) => {
    state.pagination.pageNum = action.payload;
  },
};

const applicationsReducer = {
  fetchApplications: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<{ withoutLoader?: boolean } | undefined>
  ) => {
    state.isListLoading = !action.payload?.withoutLoader;
  },
  fetchAllApplications: (state: Draft<IApplicationsState>) => {
    state.isListLoading = true;
  },
  fetchApplicationsSuccess: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<{
      urlKey?: string | null;
      list: TApplication[];
      cardsApplications: IInitialColumns;
      totalCount: string;
      jobWorkflow: IWorkflow | null;
    }>
  ) => {
    state.applications.list = action.payload.list;
    state.applications.cardsApplications = action.payload.cardsApplications;
    state.applications.totalCount = action.payload.totalCount;
    state.jobUrlKey = action.payload.urlKey || null;
    state.jobWorkflow = action.payload.jobWorkflow || null;
    state.isListLoading = false;
  },
  fetchAllApplicationsSuccess: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<TApplication[]>
  ) => {
    state.allApplications = action.payload;
    state.isListLoading = false;
  },
  fetchApplicationsFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isListLoading = false;
  },
  fetchSelectedJobApplications: (
    state: Draft<IApplicationsState>,
    _: PayloadAction<{ job_id: string; candidateUrlKey: string }>
  ) => {
    state.isLoading = true;
  },
  fetchSelectedJobApplicationsSuccess: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<TSelectedJobCandidateApplication[]>
  ) => {
    state.isLoading = false;
    state.applications.selectedJobApplications = action.payload;
  },
  fetchSelectedJobApplicationsFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  setSelectedJobApplications: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<TSelectedJobCandidateApplication[]>
  ) => {
    state.applications.selectedJobApplications = action.payload;
  },
  resetSelectedApplications: (state: Draft<IApplicationsState>) => {
    state.selectedApplications = initialState.selectedApplications;
  },
  fetchSelectedJobApplicationAnswers: (
    state: Draft<IApplicationsState>,
    _: PayloadAction<{ jobId: string; applicationId: string }>
  ) => {
    state.isLoading = true;
  },
  fetchSelectedJobApplicationAnswersSuccess: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<
      {
        readonly value: string;
        readonly label: string;
      }[]
    >
  ) => {
    state.isLoading = false;
    state.candidateAnswers = action.payload;
  },
  fetchSelectedJobApplicationAnswersFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  setApplicationStatus: (
    state: Draft<IApplicationsState>,
    _: PayloadAction<{
      applicationId: string;
      newStatus: string;
      index: string;
    }>
  ) => {
    state.error = false;
  },
  setApplicationStatusSuccess: (state: Draft<IApplicationsState>) => {
    state.error = false;
  },
  setApplicationStatusFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
  },
  updateApplicationStatus: (
    __: Draft<IApplicationsState>,
    _: PayloadActionWithCallback<{
      applicationId: string;
      newStatus: string;
      index: string;
    }>
  ) => {},
  updateApplicationStatusSuccess: (state: Draft<IApplicationsState>) => {
    state.isLoading = false;
  },
  updateApplicationStatusFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  setSelectedApplications: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<TApplication | null>
  ) => {
    let updatedApplications: TApplication[] = [];

    if (action.payload) {
      if (
        state.selectedApplications.findIndex(
          (application: TApplication) =>
            application.application_id === action.payload?.application_id
        ) > -1
      ) {
        updatedApplications = state.selectedApplications.filter(
          (application: TApplication) =>
            application.application_id !== action.payload?.application_id
        );
      } else
        updatedApplications = [...state.selectedApplications, action.payload];
    } else updatedApplications = [];
    state.selectedApplications = updatedApplications;
  },
  resetCurrentApplication: (state: Draft<IApplicationsState>) => {
    state.applications.selectedJobApplications =
      initialState.applications.selectedJobApplications;
  },
  fetchSelectedJobOwners: (
    state: Draft<IApplicationsState>,
    _: PayloadAction<string>
  ) => {
    state.isOwnersLoading = true;
  },
  fetchSelectedJobOwnersFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isOwnersLoading = false;
  },
  fetchSelectedJobOwnersSuccess: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<{ owners: TJobOwner[] }>
  ) => {
    state.isOwnersLoading = false;
    state.selectedJobOwners = action.payload.owners;
  },
  resetJobOwnersAndActivities: (state: Draft<IApplicationsState>) => {
    state.selectedJobOwners = initialState.selectedJobOwners;
    state.activityLog = initialState.activityLog;
  },
  resetSingleJobUrlKey: (state: Draft<IApplicationsState>) => {
    state.jobUrlKey = initialState.jobUrlKey;
  },
  fetchActivityLog: (
    state: Draft<IApplicationsState>,
    _: PayloadAction<string>
  ) => {
    state.isLoading = true;
  },
  fetchActivityLogFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isLoading = false;
  },
  fetchActivityLogSuccess: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<any>
  ) => {
    state.isLoading = false;
    state.activityLog = action.payload.application_activities;
  },
  deleteApplication: (
    state: Draft<IApplicationsState>,
    _: PayloadActionWithCallback<{
      readonly applicationId: number;
    }>
  ) => {
    state.isDeleteApplicationLoading = true;
  },
  deleteApplicationSuccess: (state: Draft<IApplicationsState>) => {
    state.isDeleteApplicationLoading = false;
  },
  deleteApplicationFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isDeleteApplicationLoading = false;
  },
  deleteApplications: (
    state: Draft<IApplicationsState>,
    _: PayloadActionWithCallback<{
      readonly applicationsIds: number[];
    }>
  ) => {
    state.isDeleteApplicationLoading = true;
  },
  deleteApplicationsSuccess: (state: Draft<IApplicationsState>) => {
    state.isDeleteApplicationLoading = false;
  },
  deleteApplicationsFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isDeleteApplicationLoading = false;
  },
  updateApplicationAnswers: (
    state: Draft<IApplicationsState>,
    _: PayloadActionWithCallback<{ applicationId: string; data: any }>
  ) => {
    state.isUpdateAnswersLoading = true;
  },
  updateApplicationAnswersSuccess: (state: Draft<IApplicationsState>) => {
    state.isUpdateAnswersLoading = false;
  },
  updateApplicationAnswersFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    state.isUpdateAnswersLoading = false;
    state.error = action.payload;
  },
  requestApplicationUnlock: (
    state: Draft<IApplicationsState>,
    _: PayloadActionWithCallback<{ applicationId: string }>
  ) => {
    state.isRequestUnlockLoading = true;
  },
  requestApplicationUnlockSuccess: (state: Draft<IApplicationsState>) => {
    state.isRequestUnlockLoading = false;
  },
  requestApplicationUnlockFailed: (
    state: Draft<IApplicationsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isRequestUnlockLoading = false;
  },
};

export const ApplicationsSlice = createSlice({
  name: "APPLICATIONS",
  initialState,
  reducers: {
    ...filtersReducer,
    ...paginationReducer,
    ...applicationsReducer,
    resetApplicationsState: () => initialState,
  },
});

export const {
  fetchApplicationFromTalentPool,
  fetchApplicationFromTalentPoolSuccess,
  fetchApplicationFromTalentPoolFailed,
  setView,
  setApplicationFilters,
  resetApplicationsFilters,
  setPageNum,
  fetchApplications,
  fetchAllApplications,
  fetchApplicationsSuccess,
  fetchAllApplicationsSuccess,
  fetchApplicationsFailed,
  fetchSelectedJobApplications,
  fetchSelectedJobApplicationsSuccess,
  fetchSelectedJobApplicationsFailed,
  fetchSelectedJobApplicationAnswers,
  fetchSelectedJobApplicationAnswersFailed,
  fetchSelectedJobApplicationAnswersSuccess,
  setSelectedJobApplications,
  setApplicationStatus,
  setApplicationStatusSuccess,
  setApplicationStatusFailed,
  updateApplicationStatus,
  updateApplicationStatusSuccess,
  updateApplicationStatusFailed,
  setSelectedApplications,
  resetCurrentApplication,
  resetSelectedApplications,
  fetchSelectedJobOwners,
  fetchSelectedJobOwnersFailed,
  fetchSelectedJobOwnersSuccess,
  resetJobOwnersAndActivities,
  fetchActivityLog,
  fetchActivityLogFailed,
  fetchActivityLogSuccess,
  deleteApplication,
  deleteApplicationFailed,
  deleteApplicationSuccess,
  deleteApplications,
  deleteApplicationsFailed,
  deleteApplicationsSuccess,
  updateApplicationAnswers,
  updateApplicationAnswersSuccess,
  updateApplicationAnswersFailed,
  resetSingleJobUrlKey,
  setJobWorkflow,
  requestApplicationUnlock,
  requestApplicationUnlockFailed,
  requestApplicationUnlockSuccess,
} = ApplicationsSlice.actions;

export default ApplicationsSlice.reducer;
