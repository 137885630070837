// chart configs
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { ApexOptions } from "apexcharts";
import { RangeType } from "rsuite/esm/DateRangePicker/types";
import { TFunction } from "i18next";

export const NOAChartOptions: ApexOptions = {
  chart: { toolbar: { show: false }, zoom: { enabled: false }, type: "area" },
  dataLabels: { enabled: false },
  stroke: { curve: "straight" },
  yaxis: { min: 0, stepSize: 1 },
  fill: {
    type: "gradient",
    gradient: {
      type: "horizontal",
      colorStops: [
        { color: "#FF6E7F", offset: 0, opacity: 0.5 },
        { color: "#BFE9FF", offset: 100, opacity: 0.5 },
      ],
    },
  },
};

export const SOHChartOptions = ({
  isMultiple,
}: {
  isMultiple: boolean;
}): ApexOptions => ({
  chart: { height: 350, type: "radialBar" },
  plotOptions: {
    radialBar: {
      dataLabels: {
        name: {
          fontSize: "22px",
        },
        value: {
          fontSize: "16px",
          formatter: (res) => res.toString(),
        },
        total: {
          show: isMultiple,
          label: localStorage.getItem("lang") === "en_US" ? "Total" : "Gesamt",
          formatter: ({ config }) => {
            const sum = config.series.reduce(
              (acc: number, item: number) => acc + item,
              0,
            );
            return sum.toString();
          },
        },
      },
    },
  },
});

export const ADSChartOptions: ApexOptions = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: { show: false },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        total: {
          enabled: true,
          offsetX: 0,
        },
      },
    },
  },
  stroke: {
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    y: {
      formatter: (val: number) => `${val} days`,
    },
  },
  fill: {
    colors: ["#0E63AC", "#178CF2", "#5FB2FA", "#C6E5FF"],
    opacity: 1,
  },
  legend: {
    show: false,
  },
};

export const predefinedRanges = (t: TFunction): RangeType[] => [
  {
    label: t("analytics.today") as string,
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: t("analytics.yesterday") as string,
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: t("analytics.thisWeek") as string,
    value: [
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      endOfWeek(new Date(), { weekStartsOn: 1 }),
    ],
    placement: "left",
  },
  {
    label: t("analytics.last7Days") as string,
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: t("analytics.thisMonth") as string,
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: t("analytics.lastMonth") as string,
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
];
